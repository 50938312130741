<template>
  <div>
    <contact-card
      v-if="contactContent"
      :info="contactContent"
      :colorSet="colorSet.CLEAN"
    />
    <info-card v-if="infoCardsContent" :infoCardsContent="infoCardsContent" />
  </div>
</template>

<script>
import InfoCard from "./InfoCard";
import ContactCard from "./ContactCard";

import { WEBSITE_COLOR_SET } from "@/config/constants";
import { WEBSITE_BUILDER_SECTIONS } from "@/config/constants";

export default {
  name: "website-contact",
  components: {
    "info-card": InfoCard,
    "contact-card": ContactCard
  },
  props: {
    contactSections: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      colorSet: WEBSITE_COLOR_SET,
      contactContent: null,
      infoCardsContent: null
    };
  },
  watch: {
    contactSections(newValue) {
      newValue.forEach(section => {
        if (section.id === WEBSITE_BUILDER_SECTIONS.PROFILE_CARD.id) {
          this.contactContent = section.websiteContents
            ? section.websiteContents[0]
            : null;
        } else {
          if (section.id === WEBSITE_BUILDER_SECTIONS.PROFILE.id) {
            this.infoCardsContent = section.websiteContents;
          }
        }
      });
    }
  }
};
</script>

<style scoped></style>
