<template>
  <div class="position-relative">
    <navbar
      :currentSection="sections.WALL"
      :loading="loading"
      @changeSection="changeSection"
    />
    <website-loader v-if="loading" />
    <template v-else>
      <website-header
        currentSectionName="CARTELERA"
        v-if="headerContent"
        :content="headerContent"
      />
      <website-contact :contactSections="contactSections" />
      <website-footer v-if="footerContent" :content="footerContent" />
    </template>

    <v-speed-dial
      bottom
      right
      fixed
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn color="blue" fab class="call-to-action">
          <v-icon color="white">mdi-message-bulleted</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        small
        color="green"
        href="https://api.whatsapp.com/send?phone=584142703006"
        target="_blank"
      >
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="#00A2F3"
        href="https://twitter.com/venusalive"
        target="_blank"
      >
        <v-icon>mdi-twitter</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="#A42FB1"
        href="https://www.instagram.com/venusaterapeuta/"
        target="_blank"
      >
        <v-icon>mdi-instagram</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="#0147AF"
        href="https://m.facebook.com/venusaterapeuta"
        target="_blank"
      >
        <v-icon>mdi-facebook</v-icon>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Navbar from "./Navbar";
import Header from "./Header";
import WebsiteContact from "./WebsiteContact";
import Footer from "./Footer";

import { WEBSITE_SECTIONS } from "@/config/constants";
import { WEBSITE_BUILDER_SECTIONS } from "@/config/constants";

import Repository from "@/services/repositories/repositoryFactory";
const WebsiteRepository = Repository.get("website");

import WebsiteLoader from "@/components/common/loaders/WebsiteLoader";
export default {
  name: "container-website-therapy",
  components: {
    navbar: Navbar,
    "website-header": Header,
    "website-contact": WebsiteContact,
    "website-footer": Footer,
    "website-loader": WebsiteLoader
  },
  data() {
    return {
      websiteSections: [],
      sections: WEBSITE_SECTIONS,
      loading: false,

      headerContent: null,
      footerContent: null,
      contactSections: []
    };
  },
  methods: {
    ...mapMutations("ux", ["toggleBackgroundDark"]),
    changeSection(section) {
      window.scrollTo(0, 0);
      if (section === this.sections.HOME) {
        this.$router.push({ name: "website-container-home" });
      } else {
        if (section === this.sections.THERAPIES) {
          this.$router.push({ name: "website-container-therapy" });
        }
      }
    },
    async loadAllSectionsAndContents() {
      this.loading = true;
      this.websiteSections = await WebsiteRepository.getAllSectionsAndContents().finally(
        () => {
          this.loading = false;
        }
      );
      if (this.websiteSections) {
        this.websiteSections.forEach(section => {
          switch (section.id) {
            case WEBSITE_BUILDER_SECTIONS.HEADER.id:
              {
                this.headerContent = section.websiteContents;
              }
              break;
            case WEBSITE_BUILDER_SECTIONS.FOOTER.id:
              {
                this.footerContent = section.websiteContents
                  ? section.websiteContents[0]
                  : null;
              }
              break;
            case WEBSITE_BUILDER_SECTIONS.PROFILE.id:
            case WEBSITE_BUILDER_SECTIONS.PROFILE_CARD.id:
              {
                this.contactSections.push(section);
              }
              break;
          }
        });
      }
    }
  },
  mounted() {
    this.loadAllSectionsAndContents();
    this.toggleBackgroundDark(false);
  }
};
</script>

<style scoped>
.call-to-action {
  z-index: 50;
}
.position-relative {
  position: relative !important;
}
</style>
