<template>
  <website-container-wall-view />
</template>

<script>
import WebsiteContainerWallView from "@/components/website/WebsiteContainerWall.vue";
export default {
  name: "website-container-wall",
  components: {
    "website-container-wall-view": WebsiteContainerWallView
  },
  data() {
    return {};
  }
};
</script>

<style></style>
